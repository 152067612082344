
const symbolModal = {
    modalId: "symbolsModal",
    create: (data) => {
        const symbol = window.symbolsList.find(x=>x.name === data)
        document.body.insertAdjacentHTML('beforeend', symbolModal.modalHtml(symbol));
    },
    showModal: (symbol) => {
        document.body.insertAdjacentHTML('beforeend', symbolModal.modalHtml(symbol));
    },
    close:()=>{
        document.getElementById(symbolModal.modalId).remove();
    },
    modalHtml: (el) => {
        return `
        <!-- Modal -->
        <div id="symbolsModal" class="modal fade symbols-modal eforms-modal white show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="symbolsModal" aria-modal="true">
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" onclick="symbolModal.close()" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">x</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col"><h3 class=" title title-lg">${el.name}</h3></div>
                            </div>
                            <div class="row">
                                ${el.imageUrl &&
                                    `<div class="col-4 col-sm-4">
                                        <img class="modal__image img-fluid" src="${el.imageUrl}" >
                                    </div>`
                                }
                                <div class="${el.imageUrl ? 'col-sm-8' : 'col-sm-12'} modal__textBlock">
                                    <div class="description modal__description pb-3">${el.description}</div>
                                    ${el.link &&
                                        `<a class="cta action-link modal__cta" href="${el.link}"
                                           ${el.openInNewWindow === 'true' && 'target="_blank"'}
                                        > ${el.linkLabel}</a>`
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>                
    `
    }
}

$(document).ready(function () {
    symbolModal;
});